import React, { forwardRef } from "react";
import "./Section.css";

const textWave = "assets/textWave.svg";

const Section = forwardRef((props, ref) => {
  const { title, children } = props;

  return (
    <div ref={ref} className="section">
      <div className="sectionContainer">
        <div className="textBox">
          {title}
          <div className="textStyleContainer"></div>
        </div>
        <div className="line" />
        <div className="sectionChildren">{children}</div>
      </div>
    </div>
  );
});

export default Section;
