import React, { useRef, useState, useEffect } from "react";
import Section from "./Section";
import TeamSection from "./Sections/TeamSection";
import FinancialsSection from "./Sections/FinancialsSection";
import YouTubeVideo from "./Sections/YouTubeVideo";
import PDFSection from "./Sections/PDFSection";
import DocumentsSection from "./Sections/DocumentsSections";
import AdvisorSection from "./Sections/AdvisorSection";
import ContactSection from "./Sections/ContactSection";

const Dashboard = () => {
  const sectionRefs = {
    introduction: useRef(null),
    prospectus: useRef(null),
    team: useRef(null),
    advisory: useRef(null),
    key_documents: useRef(null),

  };

  const [activeSection, setActiveSection] = useState(null);
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  const handleNavClick = (key) => {
    setUserIsScrolling(false);
    window.scrollTo({
      top: sectionRefs[key].current.offsetTop - 80,
      behavior: "smooth",
    });
    setActiveSection(key);
  };

  const TopNav = () => {
    return (
      <div className="top-nav">
        {Object.keys(sectionRefs).map((key) => (
          <button
            key={key}
            onClick={() => handleNavClick(key)}
            className={key === activeSection ? "activeNav" : "nav"}
          >
            {key
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </button>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setUserIsScrolling(true);

      let lastActiveSection = null;
      for (const [key, ref] of Object.entries(sectionRefs)) {
        const element = ref.current;
        if (element) {
          const offsetTop = element.offsetTop - 150;
          const offsetBottom = offsetTop + element.clientHeight;
          if (
            window.pageYOffset >= offsetTop &&
            window.pageYOffset <= offsetBottom
          ) {
            lastActiveSection = key;
          }
        }
      }
      if (userIsScrolling && lastActiveSection) {
        setActiveSection(lastActiveSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [userIsScrolling, sectionRefs]);

  return (
    <>
      <div className="Sections">
        <TopNav />
        <Section ref={sectionRefs.introduction} title={"introduction"}>
          <YouTubeVideo videoId={"5cGK2-C3MmM"} />
        </Section>
        <Section ref={sectionRefs.team} title={"core team"}>
          <TeamSection />
        </Section>
        <Section ref={sectionRefs.advisory} title={"advisory"}>
          <AdvisorSection />
        </Section>
        <Section ref={sectionRefs.key_documents} title={"key documents"}>
          <DocumentsSection />
        </Section>
        <Section ref={sectionRefs.prospectus} title={"prospectus"}>
          <PDFSection />
        </Section>
        <Section title={"contact"}>
          <ContactSection />
        </Section>
      </div>
    </>
  );
};

export default Dashboard;
