import React from "react";
import { useUserAuth } from "./UserAuthContext";
import "./Header.css";

const logo = "assets/RowcaveLogo.svg";

const Header = () => {
  const { user, logout } = useUserAuth();

  return (
    <div className="headerContainer">
      <img src={logo} className="logo" alt="logo" />
      <div className="header">
        <div className="headerText">Data Room</div>
        <div className="nav">Pre-seed Round</div>
      </div>
      <>
        {user ? (
          <div className="loginInfo">
            <span>{user.email}</span>
            <button className="nav" onClick={logout}>
              Sign Out
            </button>
          </div>
        ) : (
          <div />
        )}
      </>
    </div>
  );
};

export default Header;
