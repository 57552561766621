import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "./UserAuthContext";
import { isSignInWithEmailLink } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogIn, setError] = useState(null);
  const [firebaseMessage, setFirebaseMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loginStatusChecked, setLoginStatusChecked] = useState(false);

  const { sendEmailLink, loginWithEmailLink, auth, functions, user } =
    useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // e.g http://localhost:3000/login?email=theo@rowcave.com

  useEffect(() => {
    if (user) {
      // User is logged in. You could navigate away or perform other actions.
      console.log("User is already logged in");
      return;
    }

    // Once the check is complete, update `loginStatusChecked` to true

    const urlParams = new URLSearchParams(location.search);
    const emailParam = urlParams.get("email");
    if (emailParam) {
      setEmail(emailParam);
    }

    let emailToUse = window.localStorage.getItem("emailForSignIn");

    // Fetch email from URL if not in localStorage
    if (!emailToUse) {
      const urlParams = new URLSearchParams(window.location.search);
      emailToUse = urlParams.get("email");
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (emailToUse) {
        loginWithEmailLink(emailToUse, window.location.href)
          .then((result) => {
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        setError("Could not retrieve the email for sign-in.");
      }
    }

    setLoginStatusChecked(true);
  }, []);

  const handleSendEmailLink = async () => {
    setIsLoading(true);
    setError(null);

    const currentOrigin = window.location.origin;
    console.log("Current Origin:", currentOrigin); // Debugging line

    const actionCodeSettings = {
      url: `${currentOrigin}/login?email=${encodeURIComponent(email)}`, // Include email as a parameter
      handleCodeInApp: true,
    };

    try {
      const isEmailPermitted = httpsCallable(functions, "isEmailPermitted");
      const result = await isEmailPermitted({ email });

      if (result.data.status) {
        try {
          console.log(
            "Sending email with settings:",
            actionCodeSettings,
            email
          ); // Debugging line
          await sendEmailLink(email, actionCodeSettings);
          window.localStorage.setItem("emailForSignIn", email);
          setEmailSent(true);
          setFirebaseMessage("Login email sent. Please check your email:");
        } catch (error) {
          setError(error.message);
        }
      } else {
        setError(
          "Email not registered. Please contact info@rowcave.com for access"
        );
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderEmailClientLinks = () => {
    return (
      <div className="email-client-links">
        <div className="mailApps">
          <div>
            <a href="message://">
              <img
                className="mailApp"
                src="assets/symbols/mail_ios.svg"
                alt="Apple Mail"
              />
            </a>
          </div>
          <div>
            <a href="outlook://">
              <img
                className="mailApp"
                src="assets/symbols/outlook.png"
                alt="Outlook"
              />
            </a>
          </div>
          <div>
            <a href="readdle-spark://">
              <img
                className="mailApp"
                src="assets/symbols/spark.png"
                alt="Spark"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  if (!loginStatusChecked) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login">
      <div className="login__container">
        <strong>Investor Login</strong>

        {!emailSent ? (
          <>
            <input
              type="email"
              className="login__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
            <button
              className="login__btn"
              onClick={handleSendEmailLink}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Send Email Link"}
            </button>
          </>
        ) : null}

        {firebaseMessage && <div>{firebaseMessage}</div>}
        {emailSent ? renderEmailClientLinks() : null}
        {errorLogIn && (
          <div style={{ fontWeight: "bold", color: "red" }}>{errorLogIn}</div>
        )}
      </div>
    </div>
  );
}

export default Login;
