import React from "react";
import TeamMember from "../TeamMember";

const Theo = "assets/team/Theo.jpg";
const Will = "assets/team/Will.jpg";
const Ignacio = "assets/team/Ignacio.jpg";

const TeamSection = () => {
  return (
    <div className="teamMembers">
      <TeamMember
        image={Theo}
        fullName={"Theo Rigden"}
        title="Founder, CEO"
        background={
          "Product designer with a focus on immersive fitness in the luxury and professional markets"
        }
        responsibilties={"Operations, people, Rowcave Platform development"}
        LinkedInURL={"https://www.linkedin.com/in/theorigden/"}
        email="theo@rowcave.com"
      ></TeamMember>
      <TeamMember
        image={Will}
        fullName={"Will Satch MBE"}
        title="Co-Founder, Head of Sales"
        background={
          "Olympic Champion (Rio 2016), and 3 Times World Champion rower. Decorated Professional Coach"
        }
        responsibilties={
          "Professional sales, outreach, brand building and product development"
        }
        LinkedInURL={
          "https://www.linkedin.com/in/william-spencer-satch-mbe-b19b38180/"
        }
        InstagramURL={"https://www.instagram.com/will_satch/"}
        email="willsatch@rowcave.com"
      ></TeamMember>
      <TeamMember
        image={Ignacio}
        fullName={"Ignacio Villanueva"}
        title="Head of Development"
        background={
          "Aerodymamics Performance Engineer at Aston Martin F1 Team, Data Scientist at Skydweller Aero"
        }
        responsibilties={
          "Product development, data science, artificial intelligence"
        }
        LinkedInURL={"https://www.linkedin.com/in/markthomas5/overlay/photo/"}
        email="ignacio@rowcave.com"
      ></TeamMember>
    </div>
  );
};

export default TeamSection;
