import { createContext, useEffect, useState, useContext } from "react";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  signOut,
  onAuthStateChanged,
  signInAnonymously,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

import { getFirestore, setDoc, doc } from "firebase/firestore";

import { getAnalytics, logEvent } from "firebase/analytics";

import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD13kbQLlpmd1RqONnDsVkYCjS_D62Oh9U",
  authDomain: "rowcave-dataroom.firebaseapp.com",
  databaseURL:
    "https://rowcave-dataroom-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rowcave-dataroom",
  storageBucket: "rowcave-dataroom.appspot.com",
  messagingSenderId: "276156508587",
  appId: "1:276156508587:web:9367acab1267e42c358aee",
  measurementId: "G-MSS9NM7BF7",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const functions = getFunctions(app);
const recordLoginTime = httpsCallable(functions, "recordLoginTime");

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState("");

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;

      await setDoc(doc(db, "users/" + user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "Google",
        email: user.email,
        completedOnboarding: true,
      });
      //  }
    } catch (err) {
      console.error(err);
      //alert(err.message);
    }
  };

  const recordLinkClick = (linkName) => {
    logEvent(analytics, "click_event", { link_name: linkName });
  };

  const recordHoverEvent = (elementName) => {
    logEvent(analytics, "hover_event", { element_name: elementName });
  };

  const registerWithEmailAndPassword = async (name, email, password) => {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users/" + user.uid), {
      uid: user.uid,
      name: name,
      authProvider: "Email + Password",
      email: user.email,
      completedOnboarding: false,
      registeredWithEmail: true,
    });
    //updateUser(name, email);
  };

  const sendEmailLink = async (email, actionCodeSettings) => {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  };

  // const sendEmailLink = async (email) => {
  //   const provider = new GoogleAuthProvider();

  //   // To apply the default browser preference instead of explicitly setting it.
  //   provider.setCustomParameters({
  //     login_hint: email,
  //   });

  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     // The signed-in user info.
  //     const user = result.user;
  //     // Additional logic here with user information if needed.
  //   } catch (error) {
  //     // Handle Errors here.
  //     console.error(error.message);
  //   }
  // };

  const loginWithEmailLink = async (email, emailLink) => {
    await signInWithEmailLink(auth, email, emailLink);
  };

  const registerAnonymously = async () => {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };

  const logInWithEmailAndPassword = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const updateUser = async (name) => {
    console.log("update name to " + name);
    updateProfile(auth.currentUser, {
      displayName: name,
    });
  };

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const authStateChanged = async (currentUser) => {
    onAuthStateChanged(auth, currentUser);
    console.log("auth state changed");
  };

  const logout = () => {
    signOut(auth);
  };

  const recordUserLoginTime = async () => {
    try {
      const result = await recordLoginTime();
      console.log("Login time recorded: ", result.data);
    } catch (error) {
      console.error("Error recording login time: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // This means the user has logged in
        await recordUserLoginTime();
      }
    });

    return () => {
      unsubscribe(); // Cleanup subscription
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        registerWithEmailAndPassword,
        logInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        updateUser,
        sendPasswordReset,
        sendEmailLink,
        loginWithEmailLink,
        analytics,
        recordHoverEvent,
        recordHoverEvent,
        functions,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
