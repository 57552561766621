import { React, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  Navigate,
} from "react-router-dom";

import {
  UserAuthContextProvider,
  useUserAuth,
} from "./components/UserAuthContext";

import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";

import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="background">
        <div className="backgroundAnimation">
          <div className="ocean">
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </div>
      </div>

      <Router className="root">
        <UserAuthContextProvider>
          <div className="contentMain">
            <Header />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute redirectRoute="login" shouldBeSignedIn={true}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <ProtectedRoute redirectRoute="" shouldBeSignedIn={false}>
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route path="/preview" element={<Dashboard />} />
            </Routes>
          </div>
        </UserAuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
