import React from "react";
import Document from "../Document";

const CapTableImage = "assets/documents/CapTable.png";
const DocumentImage = "assets/documents/document.jpg";

export default function DocumentsSection() {
  return (
    <>
      <div className="teamMembers">
        <Document
          url={"assets/documents/[2023 10] Rowcave Cap Table.ods"}
          title={"Cap Table"}
          image={CapTableImage}
        />
        <Document
          title={"Terms Sheet"}
          url={"assets/documents/[2023 10] Rowcave Term Sheet.pdf"}
          image={DocumentImage}
        />
        <Document title={"Financial Model"} />
      </div>
    </>
  );
}
