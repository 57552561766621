import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "./UserAuthContext";

const ProtectedRoute = ({ children, redirectRoute, shouldBeSignedIn }) => {
  let { user } = useUserAuth();

  if (shouldBeSignedIn) {
    if (user) {
      console.log("authed");
      console.log(user);
    } else {
      return <Navigate to={"/" + redirectRoute} />;
    }
  } else {
    if (user) {
      return <Navigate to={"/" + redirectRoute} />;
    }
  }
  return children;
};

export default ProtectedRoute;
