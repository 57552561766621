import React from "react";
import "./PDFSection.css"; // Import your CSS file

const pdfImage = "assets/Rowcave Prospectus Cover.jpg";

const PDFSection = () => {
  return (
    <div className="pdf-container">
      <div className="downloadContainer">
        <div className="download">Download</div>
      </div>
      <a
        href="assets/[2023 10] Rowcave Prospectus.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="pdfImage"
          src={pdfImage}
          alt="View the Rowcave Prospectus"
        />
      </a>
    </div>
  );
};

export default PDFSection;
