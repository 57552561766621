import React from "react";
import TeamMember from "../TeamMember";

const Mark = "assets/team/Mark.jpg";
const Andy = "assets/team/Andy.jpg";

const TeamSection = () => {
  return (
    <div className="teamMembers">
      <TeamMember
        image={Mark}
        fullName={"Mark Thomas"}
        title="Strategy and Advisory"
        background={
          "MD Asian Le Mans and E-Bike GP Series, advisor for World Rowing, AC Milan and Chinese Sports Bureau"
        }
        responsibilties={
          "Commercial sales, partnerships, sponsorship and strategy"
        }
        LinkedInURL={"https://www.linkedin.com/in/markthomas5/"}
        email="markthomas@s2mconsulting.co.uk"
      ></TeamMember>
      <TeamMember
        image={Andy}
        fullName={"Andy Digweed"}
        title="Advisory"
        background={
          "Management and representation of World-Class Olympic and Paralympic athletes."
        }
        responsibilties={"Strategic partnerships, sponsorship and strategy"}
        LinkedInURL={"https://www.linkedin.com/in/andy-digweed-a67372a6/"}
        email="andy@sports-sphere.com"
      ></TeamMember>
    </div>
  );
};

export default TeamSection;
