import React from "react";

export default function Document({ title, subtitle, image, url }) {
  return (
    <>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="teamMember">
          <div className="square-box">
            <div className="square-content">
              <img className="profileImage" src={image}></img>
            </div>
          </div>
          <div className="name">{title}</div>
          <div>{subtitle}</div>
          <button>Download</button>
        </div>
      </a>
    </>
  );
}
